import { useRouter } from 'next/router';
import React, { useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import InputField from './Input.style';

// localized phone number
import en from 'react-phone-number-input/locale/en';
import fr from 'react-phone-number-input/locale/fr';

// get input focus class
const getInputFocusClass = value => {
  if (value !== '' && value != null) {
    return 'is-focus';
  } else {
    return '';
  }
};

const Index = props => {
  let inputElement;
  const [rows, setRows] = useState(
    props.rows === 'auto' ? props.minRows : props.rows
  );

  const { defaultLocale, locale } = useRouter();

  const textAreaChanged = event => {
    const textareaLineHeight = 20;
    const { minRows, maxRows } = props;

    const previousRows = event.target.rows;
    event.target.rows = minRows; // reset number of rows in textarea

    // 20 represent padding @TODO check if all textarea have the same padding or pass it threw props
    const currentRows = ~~(
      (event.target.scrollHeight - 20) /
      textareaLineHeight
    );

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.scrollTop = event.target.scrollHeight;
    }
    setRows(currentRows < maxRows ? currentRows : maxRows);
    props.changed(event);
  };

  let labelStyle;

  switch (props.elementType) {
    case 'input':
      inputElement = (
        <input
          {...props.elementConfig}
          id={props.elementConfig.name}
          value={props.value}
          onChange={props.changed}
          onBlur={props.onBlur}
        />
      );
      break;
    case 'phone':
      // get locale if available
      const localesPhoneNumber = { en, fr };

      const labels = localesPhoneNumber.hasOwnProperty(locale)
        ? localesPhoneNumber[locale]
        : localesPhoneNumber[defaultLocale];

      // get defaultCountry
      const app_localization = process.env.APP_LOCALIZATION.toUpperCase();

      // TODO fix vn to vu - remove this when refactored
      const defaultCountry =
        app_localization === 'VN' ? 'VU' : app_localization;

      labelStyle = { top: -20, fontSize: 14, color: '#595959' }; // force style
      inputElement = (
        <PhoneInput
          {...props.elementConfig}
          id={props.elementConfig.name}
          labels={labels}
          defaultCountry={defaultCountry}
          countrySelectProps={{ disabled: true }}
          international={false}
          value={props.value}
          onChange={value => props.changed(!!value ? value : '')}
        />
      );
      break;
    case 'select':
      inputElement = (
        <select
          id={props.elementConfig.name}
          value={props.value}
          onChange={props.changed}
        >
          {props.elementConfig.options.map(option => (
            <option
              key={option.value}
              value={option.value}
              selected={option.default}
              disabled={option.default}
              hidden={option.default}
            >
              {option.name}
            </option>
          ))}
        </select>
      );
      break;
    case 'textarea':
      inputElement = (
        <textarea
          {...props.elementConfig}
          id={props.elementConfig.name}
          style={{ lineHeight: '20px' }}
          value={props.value}
          onChange={textAreaChanged}
          rows={rows}
        />
      );
      break;
    default:
      inputElement = <input {...props} value={props.value} />;
  }
  return (
    <InputField
      style={props.style}
      className={`${getInputFocusClass(props.value)} ${props.className}`}
    >
      {inputElement}
      <span className="highlight" />
      <label htmlFor={props.elementConfig.name} style={labelStyle}>
        {props.label}
      </label>
    </InputField>
  );
};

export default Index;
